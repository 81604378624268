/* eslint-disable camelcase, react-hooks/rules-of-hooks */
import { useMutation } from 'react-query';
import axios from '@utils/axios';
import { useSnackbar } from './useSnackbar';

export function useAddAPINotification() {
  const { showMessage } = useSnackbar();
  interface RequestData {
    email: string;
    firstName: string;
    lastName: string;
  }

  const mutationFn = async ({
    email,
    firstName,
    lastName
  }: RequestData): Promise<{
    data: {
      message: string;
    };
  }> => {
    return axios.post(`/api/api-email-notifications/add`, {
      firstName,
      lastName,
      email
    });
  };

  return useMutation(mutationFn, {
    onSuccess: (data) => {
      const { message } = data?.data;
      showMessage(message);
    },
    onError: () => {
      showMessage('Error adding API email notification, please try again');
    }
  });
}
