import React, { memo, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import config from '@configFile';
import trackUse from '@utils/trackUse';
import { AppState, ThunkDispatch } from '@types';
import { logPostHogEvent } from '@utils/index';
import { dealsAction } from '../../actions';
import ProductCarousel from '../ProductCarousel';

type Props = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

const MostPopular: React.FC<Props> = ({ mostPopular, getMostPopular }) => {
  const history = useHistory();
  useEffect(() => {
    getMostPopular();
  }, []);

  if (mostPopular.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: 12 }}>
      <ProductCarousel
        tag={config.AFFILIATE_TAGS.MOST_POPULAR}
        onClick={(link: string) => {
          trackUse({
            item: 'most-popular-deal-click',
            value: link,
            type: 'CLICK'
          });

          logPostHogEvent('most-popular-deal-click', {
            value: link,
            type: 'CLICK'
          });
        }}
        lazyLoad={false}
        products={mostPopular}
        title="Popular Amazon deals"
        shouldAnimate
        seeAllClick={() => {
          history.push('/p/best-selling-deals');
          // wait a quarter second then scroll to the top
          setTimeout(() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
          }, 250);
        }}
        seeAllText="See all of the most popular deals"
      />
    </div>
  );
};

const mapStateToProps = ({ home: { mostPopular } }: AppState) => ({
  mostPopular
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getMostPopular: () => dispatch(dealsAction.getMostPopularDeals())
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MostPopular));
